import React, { Component } from 'react'
import {
  AntennaBottomControl,
  Box,
  Button,
  Card,
  InfoCard,
  Input,
  Page,
  Placeholder,
  Select,
  Tab,
  TextBox,
  SelectItemModal,
  CustomProductRow,
  InfoModal,
} from 'components'
import { Causal, CustomTmrItem, CQTag, ProductionOrderRow, QualitySheet, TestingSheet } from 'api/types'
import { EncodingPageParams } from 'pages/Encoding/Encoding'
import { getLocationState, navigate } from 'shared/router'
import RemoteConfig from 'shared/RemoteConfig'
import { T, __ } from 'translations/i18n'
import { askUserConfirmation, showToast, openModal, closeModal } from 'shared/utils'
import styled from '@emotion/styled'
import AppStore from 'AppStore'
import EncodingsQC from 'api/EncodingsQC'
import Sounds from 'shared/Sounds'
import QualitySheetModal from 'components/modals/QualitySheetModal'
import SeriesNotesModal from 'components/modals/SeriesNotesModal'
import DataDogLogger from 'shared/DataDogLogger'
import QualityControlAgent from './QualityControlAgent'
import QualityProvider from './QualityProvider'

interface State {
  locationState?: EncodingPageParams
  options: any
  errorSerie?: any
  serie?: any
  causal: Causal[]
  selectedCausal?: Causal
  tag?: CQTag
  successAction?: string
  errorAction?: string
  errorForceCollaudo?: string
  errorForceDrop?: string
  isReading: boolean
  item?: CustomTmrItem
  isPelletteria: boolean
  modalInfo?: string
  hideStartReaderButton?: boolean
  warningText?: string
  showQualitySheetModal: boolean
  showSeriesNotesModal: boolean
  lastReadedTag?: string
  qualitySheetData?: QualitySheet
  testingSheetData?: TestingSheet
  isActionInProgress: boolean
  isTagRead: boolean
  isTagWriting: boolean
  isVAS: boolean
  hasUnexpectedTag?: boolean
}
export default class QualityControl extends Component<{}, State> {
  state: State = {
    locationState: getLocationState(this.props),
    options: [
      { value: 'collaudo', label: 'collaudo', active: true },
      { value: 'scarto', label: 'scarto', active: false },
      { value: 'cancellazione', label: 'cancellazione', active: false },
    ],
    causal: [],
    tag: undefined,
    isReading: false,
    isPelletteria: (AppStore.defaultPlace?.attributes as any).categoryType === 'P',
    showQualitySheetModal: false,
    showSeriesNotesModal: false,
    isActionInProgress: false,
    isTagRead: false,
    isTagWriting: false,
    isVAS: false,
    hasUnexpectedTag: false,
  }
  operation = RemoteConfig.getOperationConfig<any>('quality-control')
  currentMode = undefined
  clearInputRef: any = undefined
  inputRef = React.createRef<HTMLInputElement>()

  async componentDidMount() {
    try {
      await this.stopReader(false, true)
      const causal = await EncodingsQC.fetchCausal(this.state.isPelletteria ? 'P' : 'C')
      this.setState({ causal })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'fetchCausal' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: error.message,
        status: 'error',
      })
    }
  }

  checkDiscrepancy = (serieCounters?: any) => {
    const { serie } = this.state
    const counters = serieCounters ?? serie.counters
    if (counters.totalPieces - counters.tested !== 0) return true
    return false
  }

  checkInspectorDiscrepancy = () => {
    const { serie } = this.state
    if (!AppStore.isInspector) return true

    if (serie.counters.toBeTested - serie.counters.inspectorTested <= 0) return false
    return true
  }

  checkInspectorAutoClose = () => {
    const { serie } = this.state
    if (!AppStore.isInspector) return true

    if (serie.counters.totalPieces - serie.counters.inspectorTested <= 0) return true
    return false
  }

  checkWorkerAutoClose = () => {
    const { serie } = this.state
    if (AppStore.isInspector) return true

    if (serie.counters.totalPieces - serie.counters.tested <= 0) return true
    return false
  }

  // da chiamare ogni volta che viene encodato un item -> serve per controllare i contatori, se è tutto fatto chiude la serie
  checkSerie = async () => {
    if (AppStore.isInspector) {
      if (await this.checkInspectorAutoClose()) this.closeSerie()
    } else if (!this.checkDiscrepancy()) this.closeSerie()
  }

  changeStatusSerie = async (status: string, msg = '', id?: string) => {
    try {
      const { serie } = this.state
      DataDogLogger.addAction('changeStatusSerie', {
        serie: serie ?? 'serie is undefined',
        status,
        msg,
        id: id ?? '-',
      })
      if (status === 'INSPECTED_2') {
        //set the serie to INSPECTED 2 calling a different endpoint
        await EncodingsQC.changeStatusSerieToInspected(id ?? serie.id)
        this.clearStatus(true)
        return true
      }
      if (status === 'SUSPENDED') {
        await EncodingsQC.changeStatusSerieToSuspended(id ?? serie.id)
      }
      if (status === 'READY_INSPECTION_2') {
        await EncodingsQC.changeStatusSerieToReadyInspection(id ?? serie.id)
      } else {
        await EncodingsQC.changeStatusSerie(serie ? serie.id : id, status)
      }
      showToast({
        title: __(T.misc.success),
        description: `Serie ${msg} ${__(T.messages.correct)}`,
        status: 'success',
      })
      this.clearStatus(true)
      return true
    } catch (error) {
      console.log(error)
      DataDogLogger.addError(error, { type: 'changeStatusSerie' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: __(T.error.serie_status_change) + status,
        status: 'error',
      })
      return false
    }
  }

  //funzione che chiude la serie
  closeSerie = async () => {
    try {
      const { serie } = this.state
      DataDogLogger.addAction('closeSerie', { serie: serie })
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      const callback = AppStore.isInspector ? this.checkInspectorDiscrepancy : this.checkDiscrepancy
      if (callback()) {
        if (await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.serie_found_discrepancy))) {
          this.clearInputRef()
          if (AppStore.isInspector) await EncodingsQC.closeSerie(serie.id)
          else await this.changeStatusSerie('READY_INSPECTION_2', 'chiusa')
          this.clearStatus(true)
          return true
        }
        this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
        return false
      }
      //quando parte la chiusura automatica per l'ispettore ma c'è almeno uno scartato allora mandiamo un messaggio se continuare o meno
      if (serie.counters.discardedPieces > 0) {
        if (
          !(await askUserConfirmation(
            __(T.confirm.discarded_pieces_title),
            __(T.confirm.discarded_pieces_close_message)
          ))
        )
          return
      }
      if (!AppStore.isInspector) {
        await this.changeStatusSerie('READY_INSPECTION_2', __(T.messages.closed))
        this.clearInputRef()
      } else {
        await EncodingsQC.closeSerie(serie.id)
        this.clearStatus(true)
        this.clearInputRef()
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.serie_closed),
          status: 'success',
        })
      }
      return true
    } catch (error) {
      DataDogLogger.addError(error, { type: 'closeSerie' })
      this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
      showToast({
        sound: false,
        title: __(T.error.error),
        description: error.message,
        status: 'error',
      })
      return false
    }
  }

  //funzione chiamata quando si cambia operazione in alto
  changeOptions = async (selectedOption: string) => {
    const { options, serie } = this.state
    DataDogLogger.addAction('changeOptions', { serie: serie ?? 'serie non presente', options })
    options.map((option) => {
      option.active = option.value === selectedOption
      return option
    })

    await this.stopReader() //stop the reader during operations change
    this.setState({
      options,
      selectedCausal: undefined,
      errorAction: undefined,
      errorForceCollaudo: undefined,
      errorForceDrop: undefined,
      errorSerie: undefined,
      successAction: undefined,
      lastReadedTag: undefined,
      isReading: false,
    })
  }

  stopReader = async (hideStartReaderButton = false, forceStop = false) => {
    try {
      const { options } = this.state
      await QualityControlAgent.stopReader()
      const mode = options.find((opt) => opt.active === true).value
      const isReading = forceStop ? false : mode === 'collaudo'
      this.setState({ isReading, hideStartReaderButton })
    } catch (error) {
      console.log(error)
      DataDogLogger.addError(error, { type: 'stopReader' })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Error in stop reader',
        status: 'error',
      })
    }
  }

  startReader = async () => {
    const { options, selectedCausal } = this.state
    const mode = options.find((opt) => opt.active === true).value
    this.currentMode = mode
    await this.stopReader()
    if (mode === 'scarto' && !selectedCausal) {
      showToast({
        title: __(T.error.error),
        description: __(T.error.no_discard_causal_selected),
        status: 'error',
      })
      return
    }

    this.setState({
      hideStartReaderButton: true, //hide start button because reader is reading
      isReading: true,
      errorAction: undefined,
      errorForceCollaudo: undefined,
      errorSerie: undefined,
      errorForceDrop: undefined,
      successAction: undefined,
    })

    QualityControlAgent.readTag('fake', 'fake')
      .then((tag) => {
        this.onTagReadCallback(tag as CQTag)
      })
      .catch((error) => {
        console.log(error)
        //if (this.isCollaudoMode()) this.startReader()
        //else
        this.setState({ isReading: false, hideStartReaderButton: false }) //show start reader because readTag is failed and the reader is not reading anymore
      })
  }

  //funzione chiamata quando si cerca una serie
  searchSerie = async (code: string) => {
    try {
      code = code.toUpperCase()
      const { isPelletteria, serie, options } = this.state
      let hideStartReaderButton = false
      const newSerie = await QualityProvider.searchSerie(
        isPelletteria,
        code,
        serie,
        this.operation,
        this.closeSerie,
        this.searchSerie,
        this.showSelectProductModal,
        this.changeStatusSerie
      )
      let modalInfo
      let warningText

      if (!newSerie) return

      DataDogLogger.addAction('searchSerie', { serie: newSerie })

      //messaggio informativo, da controllare solamente se si è ispettori e si controllano i contatori del lavorante
      if (!serie && AppStore.isInspector && this.checkDiscrepancy(newSerie?.counters))
        modalInfo = __(T.confirm.discrepancy_total_encoded_pieces)
      let hasUnexpectedTag = false
      if (newSerie && newSerie.destination && newSerie.destination.activeTag !== '') {
        if (newSerie.destination.activeTag === 'N') hideStartReaderButton = true
        warningText = newSerie.destination.fastMess
        if (warningText === 'NON DEVE CONTENERE IL TAG') hasUnexpectedTag = true
      }
      let isVAS = false
      if (newSerie && newSerie.customerOrder && newSerie.customerOrder === '24S1') isVAS = true
      const qualitySheetData = (
        await QualityProvider.searchQualitySheet(
          newSerie?.qualityCode,
          newSerie?.worker.code,
          newSerie?.product?.style?.value ?? ''
        )
      )[0] // 'Q_CODE_TEST', 'studio-pelle'
      const testingSheetData = (await QualityProvider.searchTestingSheet(newSerie?.id))[0]

      const newState = {
        errorSerie: undefined,
        serie: newSerie,
        modalInfo,
        hideStartReaderButton,
        warningText,
        qualitySheetData,
        testingSheetData,
        isVAS,
        hasUnexpectedTag,
      }
      this.setState(newState)
      const mode = options.find((opt) => opt.active === true).value
      //in scarto non faccio partire il reader automaticamente perchè va prima selezionata la causale di scarto
      if (mode !== 'scarto' && !hideStartReaderButton && newSerie) {
        this.startReader()
      }
    } catch (error) {
      console.log(error)
      DataDogLogger.addError(error, { type: 'searchSerie' })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? __(T.error.serie_error),
        status: 'error',
      })
    }
  }

  //show a list of produtcs and return the product on click
  showSelectProductModal = async (rows: ProductionOrderRow[]) => {
    rows.sort((a, b) => a.product.size.value - b.product.size.value)
    const modalId = 'cq-products'
    return new Promise((resolve, reject) => {
      openModal({
        id: modalId,
        body: (
          <SelectItemModal
            title={__(T.titles.select_product)}
            visible
            searchable
            options={rows ?? []}
            onSelect={(item) => resolve(item[0])}
            field="product.size.value"
            onClose={() => {
              closeModal(modalId)
              reject(__(T.error.product_not_selected))
            }}
            customRowRender={(row: ProductionOrderRow, selected: boolean) => {
              const { product } = row
              product.encoded = row.encoded
              product.quantity = row.quantity
              return <CustomProductRow product={product} selected={selected} />
            }}
          />
        ),
      })
    })
  }

  //funzione che mi ritorna l'azione da compiere a "destra" della pagina, andranno fatti tutti i controlli dei vari stati in base alla verify
  renderAction = () => {
    const {
      successAction,
      errorAction,
      selectedCausal,
      causal,
      options,
      errorForceCollaudo,
      errorForceDrop,
      isReading,
      isTagRead,
      isTagWriting,
      serie,
    } = this.state
    const mode = options.find((opt) => opt.active === true).value
    let message

    if (
      isReading &&
      !isTagRead &&
      !isTagWriting &&
      !errorForceCollaudo &&
      !successAction &&
      !errorForceDrop &&
      !errorAction &&
      mode !== 'scarto'
    )
      message = (
        <Box flex center>
          <Placeholder style={{ width: 370 }}>{__(T.messages.near_product_to_reader)}</Placeholder>
        </Box>
      )
    else if (successAction)
      message = (
        <TagReadBox bgColor="#18C248">
          <TagReadPlaceholder color="#000" style={{ width: 370 }}>
            {successAction}
          </TagReadPlaceholder>
        </TagReadBox>
      )
    else if (errorAction && mode !== 'scarto')
      message = (
        <TagReadBox bgColor="#ff3c3d">
          <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
            {errorAction}
          </TagReadPlaceholder>
        </TagReadBox>
      )
    else if (!successAction && !errorAction && !errorForceCollaudo && !errorForceDrop && isTagRead)
      message = (
        <TagReadBox bgColor="#18a0c2">
          <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
            {!isTagWriting && isReading
              ? 'Prodotto rilevato. Verifica in corso... Non muoverlo dal lettore!'
              : 'Battezzo in corso. Non muovere il prodotto dal lettore!'}
          </TagReadPlaceholder>
        </TagReadBox>
      )
    else if (mode === 'scarto' && !errorForceDrop && !errorForceCollaudo)
      message = (
        <Box flex style={{ width: '100%' }}>
          <Select
            onSelect={(causal) => {
              DataDogLogger.addAction('Causal selection', { serie, causal })
              this.setState({ selectedCausal: causal })
            }}
            placeholder={__(T.placeholder.drop_causal)}
            options={causal}
            config={{ value: 'id', label: 'fastId', secondaryLabel: 'description' }}
            isClearable={false}
            defaultValue={selectedCausal}
            multiLabel
          />
          <Box flex center>
            {isReading &&
              !isTagRead &&
              !isTagWriting &&
              !errorForceCollaudo &&
              !successAction &&
              !errorForceDrop &&
              !errorAction && <Placeholder style={{ width: 370 }}>{__(T.messages.near_product_to_reader)}</Placeholder>}
            {!errorAction && !successAction && !errorForceDrop && !errorForceCollaudo && isTagRead && (
              <TagReadBox bgColor="#18a0c2">
                <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
                  {!isTagWriting && isTagRead
                    ? 'Prodotto rilevato. Verifica in corso... Non muoverlo dal lettore!'
                    : 'Battezzo in corso. Non muovere il prodotto dal lettore!'}
                </TagReadPlaceholder>
              </TagReadBox>
            )}
            {errorAction && (
              <TagReadBox bgColor="#ff3c3d">
                <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
                  {errorAction}
                </TagReadPlaceholder>
              </TagReadBox>
            )}
            {successAction && (
              <TagReadBox bgColor="#18C248">
                <TagReadPlaceholder color="#000" style={{ width: 370 }}>
                  {successAction}
                </TagReadPlaceholder>
              </TagReadBox>
            )}
          </Box>
        </Box>
      )
    else if (errorForceCollaudo)
      message = (
        <Box>
          <TextBox text={errorForceCollaudo} type="error" />
          <Box row mt={15} center>
            <Button variant="default" title={__(T.misc.cancel)} onClick={() => this.clearStatus(false, false)} />
            <Spacer />
            <Button
              title={__(T.misc.confirm)}
              onClick={() => this.forceCollaudo(AppStore.isInspector ? 'CQ2' : 'CQ')}
            />
          </Box>
        </Box>
      )
    else if (errorForceDrop)
      message = (
        <Box>
          <TextBox text={errorForceDrop} type="error" />
          <Box row mt={15} center>
            <Button variant="default" title={__(T.misc.cancel)} onClick={this.clearStatus} />
            <Spacer />
            <Button title={__(T.misc.confirm)} onClick={this.processDrop} />
            <Spacer />
            <Button title="Irreversibile" onClick={() => this.processDrop(false)} />
          </Box>
        </Box>
      )
    return message
  }

  isCollaudoMode = () =>
    this.state.options.find((opt) => opt.active === true).value === 'collaudo' && this.state.isPelletteria

  //funzione che viene chiamata alla lettura di un tag, chiama la verify
  onTagReadCallback = async (tagRead: CQTag) => {
    const { serie, lastReadedTag, options, isPelletteria } = this.state
    const mode = options.find((opt) => opt.active === true).value
    if (this.currentMode !== mode) return
    try {
      //develop
      //tagRead.uid = '04E149D2B16C80'

      //se non è selezionata nessuna serie allora non vado avanti con le operazioni e ignoro
      if (!serie) return
      if (tagRead.status === '1') throw Error(__(T.error.reader_connection_error))
      if (tagRead.uid === '' || tagRead.status !== '0' || tagRead.uid === lastReadedTag) throw Error('restart reader')

      //Use for test
      // tagRead = {
      //   errorDesc: '',
      //   status: '0',
      //   uid: '045442D2B16C80',
      //   uuid: '045442D2B16C80045442D2B16C80',
      // }
      this.setState({ tag: tagRead, isTagRead: true })
      this.verify(tagRead)
    } catch (err) {
      console.log(err)
      console.log(tagRead)
      if (this.isCollaudoMode() && err.message === 'restart reader' && tagRead.status !== '99') {
        //if the error isn't "Nessun tag letto" -> status 4, and is not the last readed tag it will show an error message and restart the reader
        if (tagRead.status !== '4' && tagRead.status !== '0')
          showToast({
            sound: false,
            title: __(T.error.error),
            description: tagRead.errorDesc,
            status: 'error',
          })
        setTimeout(() => {
          this.startReader()
        }, 500)
        return
      }
      this.setState({ isReading: this.isCollaudoMode(), hideStartReaderButton: this.isCollaudoMode() }) //show start reader button because the reader is not reading anymore cause the callback is called
      if (tagRead.status !== '4' && tagRead.status !== '99')
        if (err.message === 'restart reader' && !isPelletteria) err.message = __(T.error.product_already_read)

      if (err.message !== 'restart reader')
        showToast({
          sound: false,
          title: __(T.error.error),
          description: err.message,
          status: 'error',
        })
    }
  }

  //funzione che forza il collaudo
  forceCollaudo = async (status: string) => {
    try {
      const { tag, serie, item } = this.state
      DataDogLogger.addAction('forceCollaudo', { serie, tag, item })

      //non fa l'encoding se il numero di encodati è uguale al totale
      if (AppStore.isInspector) {
        if (this.checkInspectorAutoClose()) throw Error(__(T.error.serie_limit_associate_error))
      } else if (this.checkWorkerAutoClose()) throw Error(__(T.error.serie_limit_associate_error))
      if (!AppStore.emulation) {
        this.setState({ isTagWriting: true })
        const cliente = serie.customerOrder?.trim() || 'N00035'
        //if the string is less than 6 characters it will add 0 at the end of the string
        const clienteFinal = cliente.length < 6 ? cliente.padEnd(6, '0') : cliente
        const res = await QualityControlAgent.writeTagCQ(
          AppStore.loggedUser?.username as string,
          AppStore.defaultPostazione ?? '',
          tag?.uid as string,
          serie.worker.syncCode,
          serie.code,
          (serie.data.values.Stagione as string) ?? '-',
          (serie.product.sku as string) ?? '',
          serie.product.size?.value ?? '',
          clienteFinal,
          serie.nationCode ?? ''
        )
        if (res?.status !== '0') {
          console.log(res)
          throw Error(__(T.error.writing_tag_error))
        }
      }
      await EncodingsQC.forceCollaudo({
        ...(item as any),
        status,
        causal: undefined,
        upc: serie.product.code,
        productionOrderRow: serie.row,
        product: serie.product,
      })

      const updatedSerie = await this.getUpdatedSerie()
      await this.checkSerie()

      if (!this.clearInputAndHideStartButton(tag?.uid))
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.product_associated_correctly),
          status: 'success',
        })
      this.setState({
        serie: updatedSerie,
        errorAction: undefined,
        errorForceCollaudo: undefined,
        errorForceDrop: undefined,
        errorSerie: undefined,
        isReading: false,
        lastReadedTag: tag?.uid,
        isTagRead: false,
        isTagWriting: false,
      })
      this.isCollaudoMode() && this.startReader()
    } catch (error) {
      DataDogLogger.addError(error, { type: 'forceCollaudo' })
      this.setState({ isReading: false, hideStartReaderButton: false, isTagRead: false, isTagWriting: false })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  processDrop = async (reversible = true) => {
    const { selectedCausal, tag, serie } = this.state
    try {
      DataDogLogger.addAction('processDrop', { serie, tag, selectedCausal })
      if (!AppStore.emulation) {
        this.setState({ isTagWriting: true })
        const res = await QualityControlAgent.writeTagSC(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? '',
          selectedCausal?.id as string
        )
        if (res?.status !== '0') {
          console.log(res)
          throw Error(__(T.error.writing_tag_error))
        }
      }
      const response = await EncodingsQC.drop({
        productionOrderId: serie.id,
        productId: serie.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
        causalId: selectedCausal?.id,
        reversible,
      })

      if (!response.success) throw Error(__(T.error.product_drop_error))
      const updatedSerie = await this.getUpdatedSerie()
      Sounds.success()
      this.setState({
        successAction: __(T.messages.product_dropped_correctly),
        serie: updatedSerie,
        errorForceDrop: undefined,
        errorAction: undefined,
        errorForceCollaudo: undefined,
        errorSerie: undefined,
        isReading: false,
        isTagRead: false,
        isTagWriting: false,
      })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDrop' })
      console.log(error)
      this.setState({ isReading: false, hideStartReaderButton: false, isTagRead: false, isTagWriting: false })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  processAssociate = async () => {
    const { tag, serie } = this.state
    try {
      DataDogLogger.addAction('processAssociate', { serie, tag })
      if (AppStore.isInspector) {
        if (this.checkInspectorAutoClose()) throw Error(__(T.error.serie_limit_associate_error))
      } else if (this.checkWorkerAutoClose()) throw Error(__(T.error.serie_limit_associate_error))
      if (!AppStore.emulation) {
        this.setState({ isTagWriting: true })
        const cliente = serie.customerOrder?.trim() || 'N00035'
        //if the string is less than 6 characters it will add 0 at the end of the string
        const clienteFinal = cliente.length < 6 ? cliente.padEnd(6, '0') : cliente
        const res = await QualityControlAgent.writeTagCQ(
          AppStore.loggedUser?.username as string,
          AppStore.defaultPostazione ?? '',
          tag?.uid as string,
          serie.worker.syncCode,
          serie.code,
          (serie.data.values.Stagione as string) ?? '-',
          (serie.product.sku as string) ?? '',
          serie.product.size?.value ?? '',
          clienteFinal,
          serie.nationCode ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.writing_tag_error))
        }
      }
      const response = await EncodingsQC.associate({
        productionOrderId: serie.id,
        productId: serie.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
      })

      if (!response.success) throw Error(__(T.error.product_association_error))

      const updatedSerie = await this.getUpdatedSerie()
      await this.checkSerie()

      if (!this.clearInputAndHideStartButton(tag?.uid)) {
        Sounds.success()
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.product_associated_correctly),
          status: 'success',
        })
        this.setState({
          serie: updatedSerie,
          isReading: false,
          lastReadedTag: tag?.uid,
          isTagRead: false,
          isTagWriting: false,
        })
      }
      this.isCollaudoMode() && this.startReader()
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processAssociate' })
      console.log(error)
      this.setState({ isReading: false, hideStartReaderButton: false, isTagRead: false, isTagWriting: false })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  processDelete = async () => {
    const { tag, serie, item } = this.state
    try {
      DataDogLogger.addAction('processDelete', { tag, serie, item })
      if (!AppStore.emulation) {
        this.setState({ isTagWriting: true })
        const res = await QualityControlAgent.cancelTag(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.tag_reset_error))
        }
      }
      await EncodingsQC.delete({
        productionOrderId: item?.productionOrderRow?.order?.id ?? serie.id,
        productId: item?.product?.id as string,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
      })

      const updatedSerie = await this.getUpdatedSerie()
      Sounds.success()
      this.setState({
        successAction: __(T.messages.product_cancel_correctly),
        serie: updatedSerie,
        isReading: false,
        isTagRead: false,
        isTagWriting: false,
      })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDelete' })
      console.log(error)
      this.setState({ isReading: false, hideStartReaderButton: false, isTagRead: false, isTagWriting: false })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  verify = async (tag: CQTag) => {
    try {
      const { options, serie } = this.state
      await QualityProvider.verify(
        tag,
        options,
        serie,
        this.operation,
        this.setError,
        (item) => {
          this.setState({
            item,
            errorForceCollaudo: `${__(T.messages.product_already_dropped_info)} "${
              item.causal?.description ?? 'N/A'
            } \n ${__(T.messages.product_association_proceed)}"`,
          })
        },
        this.processAssociate,
        this.processDrop,
        this.processDelete,
        (item) => this.setState({ item }),
        this.forceCollaudo,
        () => {
          this.setState({ errorForceDrop: __(T.messages.drop_proceed) })
        },
        this.stopReader
      )
    } catch (err) {
      console.log(err)
      this.setError(err?.message ?? 'Generic error')
    }
  }

  setError = async (msg: string) => {
    await this.stopReader(false, true)
    this.setState({
      errorAction: msg,
      item: undefined,
      successAction: undefined,
      isTagRead: false,
      isTagWriting: false,
    })
  }

  clearInputAndHideStartButton = (tag: any) => {
    const { isPelletteria } = this.state
    if (!isPelletteria) {
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.product_associated_correctly),
        status: 'success',
      })

      this.clearInputRef && this.clearInputRef()
      Sounds.success()
      this.setState({
        hideStartReaderButton: true,
        isReading: false,
        lastReadedTag: tag,
        errorAction: undefined,
        errorForceCollaudo: undefined,
        isTagRead: false,
      })
      return true
    }
    return false
  }

  closeModalInfo = () => {
    this.setState({ modalInfo: undefined })
  }

  clearStatus = (clearSerie = false, isReading = false) => {
    if (isReading) this.startReader() //restart the reader if the user cleared the status in ForceCollaudo pressing cancel, because in the verify function the reader will stop
    this.setState({
      serie: clearSerie ? undefined : this.state.serie,
      errorAction: undefined,
      successAction: undefined,
      errorForceCollaudo: undefined,
      errorForceDrop: undefined,
      errorSerie: undefined,
      isReading: isReading,
      hideStartReaderButton: false,
      modalInfo: undefined,
      warningText: undefined,
      qualitySheetData: clearSerie ? undefined : this.state.qualitySheetData,
      testingSheetData: clearSerie ? undefined : this.state.testingSheetData,
      isActionInProgress: false,
      isTagRead: false,
      isTagWriting: false,
      isVAS: false,
      hasUnexpectedTag: false,
    })
  }

  interruptCollaudo = async () => {
    try {
      const { serie } = this.state
      DataDogLogger.addAction('interruptCollaudo', { serie })
      this.setState({ isActionInProgress: true }) //disable action buttons
      if (
        await askUserConfirmation(
          __(T.messages.serie_confirm_interrupt),
          __(T.messages.serie_interrupt_association_question)
        )
      ) {
        await EncodingsQC.interruptSerie(serie.id, this.operation.id)
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.serie_interrupted_correctly),
          status: 'success',
        })
        this.clearStatus(true)
        this.clearInputRef()
      }
    } catch (error) {
      DataDogLogger.addError(error, { type: 'interruptCollaudo' })
      console.log(error)
      this.setState({
        isReading: false,
        hideStartReaderButton: false,
        isActionInProgress: false,
        isTagRead: false,
        isTagWriting: false,
      })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  getUpdatedSerie = async () => {
    const { serie } = this.state
    try {
      const data = await EncodingsQC.getSerieUpdate(serie.id, this.operation.id)
      serie.counters = {
        totalPieces: data.availableQuantity,
        tested: data.tested,
        discardedPieces: data.discarded,
        inspectorTested: data.inspectorTested,
        toBeTested: data.toBeTested > data.tested ? data.tested : data.toBeTested,
        availableQuantity: data.availableQuantity,
      }
      return serie
    } catch (error) {
      DataDogLogger.addError(error, { type: 'getUpdatedSerie' })
      console.log(error)
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  onBackPressed = async () => {
    const { serie } = this.state
    DataDogLogger.addAction('onBackPressed', { serie: serie ?? 'serie is undefined' })
    await this.stopReader(false, true)
    navigate('/')
  }

  emulateTag = (tags: string[]) => {
    const tagRead = {
      errorDesc: '',
      status: '0',
      uid: tags[0],
      uuid: '045442D2B16C80045442D2B16C80',
    }
    this.onTagReadCallback(tagRead)
  }

  toggleQualitySheetDetail = () => {
    const { showQualitySheetModal, serie } = this.state
    DataDogLogger.addAction('toggleQualitySheetDetail', {
      serie: serie ?? 'serie is undefined',
      show: !showQualitySheetModal,
    })
    this.setState({ showQualitySheetModal: !showQualitySheetModal })
  }
  toggleSeriesNotesModal = () => {
    const { showSeriesNotesModal, serie } = this.state
    DataDogLogger.addAction('toggleSeriesNotesModal', {
      serie: serie ?? 'serie is undefined',
      show: !showSeriesNotesModal,
    })
    this.setState({ showSeriesNotesModal: !this.state.showSeriesNotesModal })
  }

  setTestingSheet = (testingSheet: any) => {
    this.setState({ testingSheetData: testingSheet })
  }

  submitTestingSheet = async (testingSheet: Partial<TestingSheet>, isInModify: boolean) => {
    try {
      const { serie, qualitySheetData, testingSheetData } = this.state
      const { notes, attachmentFiles } = testingSheet

      DataDogLogger.addAction('submitTestingSheet', {
        serie: serie ?? 'serie is undefined',
        qualitySheetData,
        testingSheetData,
        notes,
      })

      if (!isInModify) {
        const files = {}
        attachmentFiles.map((file) =>
          Object.assign(files, { [file.name]: { content: file.base64, description: file.name } })
        )
        const newTestingSheet = {
          qualitySheetId: qualitySheetData?.id,
          orderId: serie.id,
          notes: notes,
          attachmentFiles: files,
        }

        await QualityProvider.createTestingSheet(newTestingSheet)
        const res = (await QualityProvider.searchTestingSheet(serie.id))[0]
        this.setState({ testingSheetData: res })
      } else {
        if (!testingSheetData) throw Error('Testing sheet not set')

        const files = {}
        attachmentFiles
          .filter((file) => !!file.preview)
          .map((file) => Object.assign(files, { [file.name]: { content: file.base64, description: file.name } }))
        const newTestingSheet = {
          id: testingSheetData.id,
          inspectionNote: testingSheet.notes as string,
          attachments: testingSheetData.attachments,
          qualitySheet: testingSheetData.qualitySheet,
          code: testingSheetData.code,
        }
        await QualityProvider.addFilesQualitySheet(newTestingSheet.code, files)
        const res = await QualityProvider.updateTestingSheet(newTestingSheet)
        this.setState({ testingSheetData: res })
      }
      this.toggleSeriesNotesModal()
    } catch (error) {
      DataDogLogger.addError(error, { type: 'submitTestingSheet' })
      showToast({
        title: __(T.error.error),
        description: error?.message ?? 'Generic error',
        status: 'error',
      })
      this.toggleSeriesNotesModal()
    }
  }

  render() {
    const {
      errorSerie,
      locationState,
      options,
      serie,
      isReading,
      modalInfo,
      hideStartReaderButton,
      warningText,
      qualitySheetData,
      testingSheetData,
      isActionInProgress,
      isTagWriting,
      isVAS,
      hasUnexpectedTag,
    } = this.state
    const { totalPieces, tested, inspectorTested, discardedPieces, toBeTested } = serie?.counters ?? {}

    const isInspector = AppStore.isInspector

    let remainPieces = isInspector ? toBeTested - inspectorTested : totalPieces - tested

    if (remainPieces < 0) remainPieces = 0

    const showReaderButton = !(hideStartReaderButton || isReading || isTagWriting)

    const headerRight = !locationState?.hideOptions ? (
      <>
        {isInspector && serie && qualitySheetData && (
          <CustomBtn onClick={this.toggleSeriesNotesModal}>Note Serie</CustomBtn>
        )}
        {qualitySheetData && (
          <Button variant="secondary" style={{ minHeight: 55 }} onClick={this.toggleQualitySheetDetail}>
            Scheda qualità
          </Button>
        )}
        <Spacer />
        <Tab options={options} onOptionSelected={this.changeOptions} />
      </>
    ) : undefined

    return (
      <Page
        title="Quality Control"
        enableEmulation
        headerRight={headerRight}
        onBackPress={serie ? this.onBackPressed : undefined}
        emulationFunction={this.emulateTag}
      >
        <Page.Sidebar style={{ overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Input
            registerClear={(cb) => (this.clearInputRef = cb)}
            inputRef={this.inputRef}
            autoFocus={this.inputRef?.current?.value === ''}
            onChange={() => this.forceUpdate()}
            barcode
            error={errorSerie}
            placeholder="Barcode serie"
            onEnter={this.searchSerie}
            disabled={isReading && !this.isCollaudoMode()}
            focusOptions={{ preventScroll: true }}
          />
          <Spacer />
          <WarningText text={warningText} type="warning" p={2} />
          <Spacer />
          {isVAS && (
            <>
              <WarningText text="ATTENZIONE SERIE VAS" type="warning" p={2} />
              <Spacer />
            </>
          )}
          {serie && (
            <>
              <InfoCard item={serie.data.values} title="Serie" subtitle={serie.code} />
              <Spacer />
              <CounterContainer row={isInspector}>
                <Card title={__(T.placeholder.total_pieces)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                  <Counter>{totalPieces}</Counter>
                </Card>
                {isInspector && (
                  <>
                    <Card title={__(T.placeholder.associated)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                      <Counter>{tested}</Counter>
                    </Card>
                    <Card title={__(T.placeholder.to_be_tested)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                      <Counter>{toBeTested}</Counter>
                    </Card>
                  </>
                )}
              </CounterContainer>
              <Spacer />
              <CounterContainer row>
                <Card title={__(T.placeholder.tested)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                  <Counter>{isInspector ? inspectorTested : tested}</Counter>
                </Card>
                <Card title={__(T.placeholder.dropped)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                  <Counter>{discardedPieces}</Counter>
                </Card>
                <Card title={__(T.placeholder.remain)} center nomargin style={{ minWidth: 125, padding: 5 }}>
                  <Counter>{remainPieces}</Counter>
                </Card>
              </CounterContainer>
              <Spacer />
              <Box flex style={{ justifyContent: 'flex-end' }}>
                <Button
                  title={__(T.placeholder.confirm_serie)}
                  loading={isActionInProgress}
                  variant="primary"
                  onClick={this.closeSerie}
                  disabled={hasUnexpectedTag}
                />
                <Spacer />
                {isInspector && (
                  <>
                    <Button
                      title={__(T.placeholder.suspend_serie)}
                      loading={isActionInProgress}
                      variant="secondary"
                      onClick={async () => {
                        await this.changeStatusSerie('SUSPENDED', __(T.messages.suspended))
                        this.clearStatus(true)
                        this.clearInputRef()
                      }}
                    />
                    <Spacer />
                    <Button
                      title={__(T.placeholder.interrupt_association)}
                      loading={isActionInProgress}
                      variant="error"
                      onClick={this.interruptCollaudo}
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </Page.Sidebar>
        <Page.Content>
          {serie && (
            <>
              <Box flex hcenter vcenter>
                {this.renderAction()}
              </Box>
              <Spacer />
              {showReaderButton && <Button title={__(T.placeholder.start_reader)} onClick={this.startReader} />}
              {isReading && <AntennaBottomControl />}
            </>
          )}
        </Page.Content>
        <InfoModal
          onClose={this.closeModalInfo}
          subtitle={modalInfo}
          buttonText={__(T.placeholder.continue)}
          isOpen={!!modalInfo}
          title={__(T.placeholder.attention)}
        />
        {this.state.showQualitySheetModal && (
          <QualitySheetModal
            serie={serie}
            qualitySheet={qualitySheetData}
            onClose={this.toggleQualitySheetDetail}
            visible={this.state.showQualitySheetModal}
          />
        )}
        {this.state.showSeriesNotesModal && (
          <SeriesNotesModal
            onSubmitCallback={(testingSheet, updateSheet) => this.submitTestingSheet(testingSheet, updateSheet)}
            testingSheet={testingSheetData}
            onClose={this.toggleSeriesNotesModal}
            visible={this.state.showSeriesNotesModal}
          />
        )}
        {/* <ImageDetailModal image={detailImage} visible={this.state.showImageDetailModal} /> */}
      </Page>
    )
  }
}

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`

const CounterContainer = styled(Box)`
  justify-content: space-between;
`
export const Spacer = styled.div`
  width: 20px;
  height: 10px;
  min-height: 10px;
  min-width: 20px;
`

const WarningText = styled(TextBox)`
  font-size: 15px;
`
const CustomBtn = styled(Button)`
  background: none;
  color: #3265c7;
  border-color: none;
  box-shadow: none;
  text-decoration: underline;
`

const TagReadBox = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 20px;
  border-radius: 20px;
`

const TagReadPlaceholder = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  cursor: default;
  ${({ color }) => `color: ${color}`};
`
